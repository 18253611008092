/**
* Template Name: NiceAdmin
* Updated: Mar 09 2023 with Bootstrap v5.2.3
* Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
    scroll-behavior: smooth;
}

body {
    font-family: Montserrat, sans-serif !important;
    background: #f6f9ff;
    color: #444444;
    letter-spacing: 0.02rem;
}

a {
    color: #4154f1;
    text-decoration: none;
}

a:hover {
    color: #717ff5;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Work Sans", sans-serif;
    margin: 0;
}

.dropdown-menu.show {
    width: 100%;
}

.filter-option-inner-inner {
    font-family: "Work Sans", sans-serif;
    font-size: 12px;
    color: #212529;
}

/*--------------------------------------------------------------
  # Main
  --------------------------------------------------------------*/
#main {
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
}

.section {
    margin: 4rem 1rem 0rem 15rem;
    min-height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
    padding: 10px 0px 15px 0;
    /* border: 1px solid red; */

    .title-createbtn-box {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: 45px;
        margin-bottom: 20px;

        .title {
            font-size: 1.4rem;
            font-weight: 600;
            color: #012970;
            margin: 0;

            @media (min-width: 1300px) {
                font-size: 2rem;
            }
        }
    }

    @media (min-width: 1300px) {
        margin: 5.2rem 2rem 0rem 20rem;
        min-height: calc(100vh - 5.2rem);
    }
}

.table-card {
    position: relative;
    border: none;
    border-radius: 5px;
    flex-grow: 1;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
    background-color: #fff;
    padding: 20px;
}

/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    /* visibility: hidden; */
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    transition: all 0.4s;
    /* border: none; */
    background-color: transparent;
}

.search-filter-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.search-box {
    /* background-image: linear-gradient(45deg, #3e135e, #093847); */
    height: 38px;
    width: 200px;
    border: 1px solid #acacad;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 15px;
    justify-content: space-between;
    transition: 0.4s ease-in-out;
}

.search-element {
    height: 100%;
    border: none;
    outline: none;
    width: 89%;
    padding-left: 15px;
    margin-right: 10px;
    /* padding-top: 2px;
    padding-bottom: 2px; */
    background-color: transparent;
    font-family: var(--font);
    font-weight: 500;
    font-size: 14px;
    color: #000;
    word-spacing: 1px;
    border-radius: 25px;
}

.search-box:hover {
    width: 260px;
    transition: 0.4s ease-in-out;
    box-shadow: 0px 1px 0px 0px #1ba6f7;
}

.search-box:hover > .search-element {
    flex-grow: 1;
    /* margin-right: 15px; */
}

.search-icon {
    font-size: 22px;
    color: #1ba6f7;
}

.noproduct-search-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
}

.product-search-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    /* border: 1px solid red; */
}

.items-per-page {
    margin-right: 1rem; /* Add spacing between the select limit dropdown and the search input */
}

.dashboard {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;
    gap: 25px;

    .info-card {
        border-radius: 10px;
        padding: 15px;
        min-width: 300px;
        min-height: 160px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        flex: 1;

        .cardName-menu-box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .card-name {
                font-size: 18px;
                font-weight: 500;
                margin: 0;
                color: #012970;
                font-family: "Work Sans", sans-serif !important;
            }
        }

        .card-icon--count--box {
            display: flex;
            align-items: center;
            gap: 15px;

            .icon-box {
                height: 70px;
                width: 70px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .card-value__text__box {
                min-width: 25%;
                h6 {
                    font-size: 20px;
                    font-weight: 600;
                }

                .text-mute {
                    font-size: small;
                    font-weight: 400;
                    color: #7f7e7e;
                    letter-spacing: 0.3px;
                }
            }
        }
    }
}

.header {
    transition: all 0.5s;
    z-index: 997;
    height: 60px;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
    padding: 20px;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h1 {
        font-size: 20px;
        font-weight: 600;
        color: #012970;
    }

    .profile-menu-box {
        display: flex;
        flex-direction: row;
        align-items: center;

        .profile-name {
            font-size: 15px;
            font-weight: 600;
            margin-left: 10px;
            cursor: pointer;
        }
    }

    @media (min-width: 1300px) {
        height: 80px;
    }
}

.opened {
    transform: rotate(180deg);
    transition: all 0.5s ease-in-out;
}

/* FontAwesome Icons */
i {
    font-size: 18px;
    margin-right: 5px;
}

/*--------------------------------------------------------------
  # Sidebar
  --------------------------------------------------------------*/
.sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 210px;
    z-index: 996;
    transition: all 0.3s;

    @media (min-width: 1300px) {
        width: 280px;
        top: 80px;
    }
}

.active-nav {
    color: #4154f1 !important;
    background-color: #d8e5f3 !important;
    transition: 0.3s ease-in-out;
}

.dropdown-icon {
    transform: rotate(180deg);
    transition: 1s ease-in-out;
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
.footer {
    padding: 20px 0 20px 25%;
    margin-left: 0;
    font-size: 14px;
    transition: all 0.3s;
    border-top: 1px solid #cddfff;
}

.footer .copyright {
    color: #012970;
    text-align: center;
}

.footer .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #012970;
}

.img--block {
    display: inline-block;
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #e5edff;
}
.img--block > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.more--text {
    text-decoration: underline;
    color: rgb(17, 128, 202);
    font-weight: 500;
    display: inline-block;
}
.fileupload--label {
    font-size: 10px;
    background: #e5edff;
    padding: 3px 10px;
    border-radius: 4px;
    font-weight: 600;
    color: #4154f1;
    cursor: pointer;
}

/* Scrollbar styles start */

/* width */
::-webkit-scrollbar {
    width: 6px; /* Vertical scrollbar width */
    height: 6px; /* Horizontal scrollbar width */
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
    opacity: 0.2;
    display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #a5d2f8;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    opacity: 0;
}

::-webkit-scrollbar-thumb:hover {
    background: #c1ddf4;
    transition: 0.2s ease-in-out;
}

/* Scrollbar styles end */

/* user create form styles starts */

.create-form {
    display: flex;
    flex-direction: column;

    .form-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #fff; /* Add a background color */
        height: 60px;
        position: sticky;
        position: -webkit-sticky;
        top: 0px;
        z-index: 1000; /* Adjust the z-index as needed */
        box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    }
}

.user-create-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    max-height: 74vh;
    overflow-y: auto;
    background-color: #fff;
    border: none;
    box-shadow: 24;
    padding: 0 20px 20px 20px;
    /* border: 1px solid red; */
    border-radius: 10px;
    outline: none;
}

.loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
}

/* ============= checkbox styles start ============= */
.checkbox-wrapper-34 {
    --blue: #16d55f;
    --g08: #e1e5eb;
    --g04: #a18e84;
    /* padding-left: 20%; */
}

.checkbox-wrapper-34 .tgl {
    display: none;
}
.checkbox-wrapper-34 .tgl,
.checkbox-wrapper-34 .tgl:after,
.checkbox-wrapper-34 .tgl:before,
.checkbox-wrapper-34 .tgl *,
.checkbox-wrapper-34 .tgl *:after,
.checkbox-wrapper-34 .tgl *:before,
.checkbox-wrapper-34 .tgl + .tgl-btn {
    box-sizing: border-box;
}
.checkbox-wrapper-34 .tgl::selection,
.checkbox-wrapper-34 .tgl:after::selection,
.checkbox-wrapper-34 .tgl:before::selection,
.checkbox-wrapper-34 .tgl *::selection,
.checkbox-wrapper-34 .tgl *:after::selection,
.checkbox-wrapper-34 .tgl *:before::selection,
.checkbox-wrapper-34 .tgl + .tgl-btn::selection {
    background: none;
}
.checkbox-wrapper-34 .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 42px;
    height: 22px;
    position: relative;
    cursor: pointer;
    user-select: none;
    font-size: 10px;
    font-weight: 400;
    color: #fff;
}
.checkbox-wrapper-34 .tgl + .tgl-btn:after,
.checkbox-wrapper-34 .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 45%;
    height: 100%;
}
.checkbox-wrapper-34 .tgl + .tgl-btn:after {
    left: 0;
}
.checkbox-wrapper-34 .tgl + .tgl-btn:before {
    display: inline;
    position: absolute;
    top: 7px;
}
.checkbox-wrapper-34 .tgl:checked + .tgl-btn:after {
    left: 56.5%;
}

.checkbox-wrapper-34 .tgl-ios + .tgl-btn {
    background: var(--g08);
    border-radius: 20rem;
    padding: 2px;
    transition: all 0.4s ease;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.checkbox-wrapper-34 .tgl-ios + .tgl-btn:after {
    border-radius: 2em;
    background: #fff;
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.checkbox-wrapper-34 .tgl-ios + .tgl-btn:before {
    content: "No";
    left: 23px;
    top: 3.5px;
    color: var(--g04);
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.checkbox-wrapper-34 .tgl-ios + .tgl-btn:active {
    box-shadow: inset 0 0 0 30px rgba(0, 0, 0, 0.1);
}
.checkbox-wrapper-34 .tgl-ios + .tgl-btn:active:after {
    padding-right: 0.4em;
}
.checkbox-wrapper-34 .tgl-ios:checked + .tgl-btn {
    background: var(--blue);
}
.checkbox-wrapper-34 .tgl-ios:checked + .tgl-btn:active {
    box-shadow: inset 0 0 0 30px rgba(0, 0, 0, 0.1);
}
.checkbox-wrapper-34 .tgl-ios:checked + .tgl-btn:active:after {
    margin-left: -0.4em;
}
.checkbox-wrapper-34 .tgl-ios:checked + .tgl-btn:before {
    content: "Yes";
    left: 4px;
    top: 4px;
    color: #fff;
}
/* ============ checkbox styles end ============ */

/* ============ table styles starts ============ */

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

table tr {
    border-bottom: 1px solid #e0e0e0;
}

th,
td {
    padding: 0.7rem;
    /* border: 1px solid #ddd; */

    @media (min-width: 1300px) {
        padding: 1rem;
    }
}

th {
    background-color: #f2f2f2;
    white-space: nowrap;
    font-size: 0.8rem;

    @media (min-width: 1300px) {
        font-size: 1rem;
    }
}

td {
    white-space: wrap;
    font-size: 0.7rem;
    font-weight: 400;
    @media (min-width: 1300px) {
        font-size: 0.9rem;
    }
}

td:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

table tr:hover {
    background-color: #f2f2f4;
}

.table-pagn-itemspp-box {
    position: relative;
    /* bottom: -35px;
    right: 20px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    height: 40px;

    .select {
        width: 50px;
        padding: 0;
        margin-right: 30px;
    }
}

.table-empty-view {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        font-size: 30px;
        font-weight: 500;
    }
}

.sort-icon {
    color: #736b6f;
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    &:hover {
        color: #000;
        transition: all 0.5s ease-in-out;
    }
}

.table-control-icons {
    font-size: 20px !important;
    cursor: pointer;

    @media (min-width: 1300px) {
        font-size: 26px !important;
    }
}

/* ============ table styles ends ============ */

/* view edit modal styles starts */

@keyframes fallIn {
    /* 0% {
        transform: scale(1) translateX(-45%) translateY(-45%);
    }
    30% {
        transform: scale(1.25) translateX(-46%) translateY(-46%);
    }
    40% {
        transform: scale(0.75) translateX(-47%) translateY(-47%);
    }
    60% {
        transform: scale(1.15) translateX(-48%) translateY(-48%);
    }
    80% {
        transform: scale(0.95) translateX(-49%) translateY(-49%);
    }
    100% {
        transform: scale(1) translateX(-50%) translateY(-50%);
    } */
    0% {
        transform: translateY(-100%) translateX(-50%) translateY(-50%);
        opacity: 0.4;
    }
    100% {
        transform: translateY(0) translateX(-50%) translateY(-50%);
        opacity: 1;
    }
}

.view-edit-modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 500px;
    max-height: 90vh;
    background-color: #fff;
    border: none;
    box-shadow: 24;
    /* padding: 0 20px 20px 20px; */
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    outline: none;
    animation: fallIn 0.7s ease-in-out;

    .modal-title-close-btn-box {
        /* border: 1px solid red; */
        border-bottom: 1px solid #ada5a5;
        height: 60px;
        padding: 0 15px;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .modal-title {
            font-size: 25px;
        }
    }
    .modal-data-box {
        /* border: 1px solid red; */
        display: flex;
        flex-direction: column;
        list-style-type: none;
        overflow-y: auto;
        padding: 15px;

        .create--form--plain-text-field-box {
            position: relative;

            .field-btn {
                position: absolute;
                top: 15px;
                right: 15px;
            }
        }

        .view-field-item {
            margin: 10px 15px;
        }

        .conf-dlg-box {
            display: flex;
            flex-direction: column;
            align-items: center;

            .cof-question {
                text-align: center;
                font-size: 18px;
                font-weight: 500;
            }

            .yes-no-btn-box {
                display: flex;
                justify-content: space-between;
                padding: 0 50px;
                /* border: 1px solid red; */
                width: 70%;
                margin-top: 15px;
            }
        }

        .filter-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            justify-content: center;

            .filter-item-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                /* justify-content: space-between; */
                gap: 15px;

                &:first-child Button {
                    margin-right: auto;
                }
            }

            .filters-action-box {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 15px;
                }
            }
        }

        .image {
            display: flex;
            flex-direction: column;

            .profile-img:hover {
                opacity: 0.8;
                transition: all 0.3s ease-in-out;
            }
        }

        .image-box {
            height: 150px;
            width: 150px;
            border-radius: 50%;
            position: relative;
            margin-top: 10px;

            .profile-img {
                width: 100%;
                height: 100%;
                cursor: pointer;
                border-radius: 50%;
                transition: all 0.3s ease-in-out;
            }
        }

        .image-box::after {
            content: "+";
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #91f0ae;
            border: 3px solid #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            font-size: 30px;
            bottom: 0;
            right: 2px;
        }
    }

    .modal-btn-box {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 15px;
        height: 55px;
        padding-right: 20px;
        flex-shrink: 0;
        /* border: 1px solid red; */
    }
}

/* view edit modal styles ends */

.two-items-per-row {
    column-count: 2;

    .item {
        list-style-type: none;
        border: 1px solid #a0a1a0;
        border-radius: 15px;
        padding: 1px 8px;
        width: fit-content;
        margin-bottom: 5px;
        font-size: 14px;
    }
}

/* loader styles starts */

.loader-box {
    height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loading-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 80px;
    border-radius: 7px;
    border: 1px solid rgb(113, 202, 237);
    background-color: transparent;
    /* opacity: 0.8; */
}
/* loader styles ends */

/* ================= login page styles starts ================= */

.login-bg-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .login-pg-title {
        font-size: 25px;
        font-weight: 600;
        color: #012970;
        margin-bottom: 20px;
    }

    .login-pg-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        padding: 35px 20px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .login-pg-form-title {
            font-size: 20px;
            font-weight: 500;
            color: #012970;
        }

        .login-pg-form-caption {
            font-size: 14px;
            font-weight: 400;
        }

        .login-pg-form-field-box {
            width: 100%;
            margin: 10px 0;
        }

        .text-error {
            color: #f2134b;
            align-self: flex-start;
            margin: 0;
        }

        .login-pg-form-fgt-psw {
            align-self: flex-end;
            font-size: 14px;
        }
    }
}

/* ================= login page styles ends ================= */

/* ================= Bulk upload styles starts ================= */
@keyframes slideIn {
    0% {
        transform: translateX(-100%);
        opacity: 0.4;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
.bulk-upload-dlg-box {
    width: 75vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border: 1px solid red; */
    box-sizing: border-box;
    position: relative;

    .upload-steps {
        display: flex;
        flex-direction: column;
        align-items: center;

        .step-1,
        .step-2,
        .step-3 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
        .step-1 {
            /* border: 1px solid red; */
            min-height: 270px;

            .select-file-box {
                width: 50%;
                height: 50%;
                border-radius: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: #eaebec;
                border: 1.4px dashed #a3abbb;
            }
        }
        .step-2 {
        }
        .step-3 {
        }
    }
}

/* ================= Bulk upload styles ends ================= */

.step-1 {
    animation: slideIn 1.5s ease-in-out;
}
.step-2 {
    animation: slideIn 1.5s ease-in-out;
}
.step-3 {
    animation: slideIn 1.5s ease-in-out;
}
